.about-content {
  container: aboutpage-content / inline-size;
  width: 100%;
  height: auto;
  padding: 0;
  background-color: var(--oe-green-yellow-300);

  .header-section {
    .banner-img {
      background-color: var(--oe-green-yellow-200);

      img {
        border-radius: 0;
        object-fit: cover;
        object-position: 50% 10%;
      }
    }
  }

  .heading {
    margin-block-start: -1rem;

    .heading-title {
      width: fit-content;
      color: var(--oe-white);
      position: relative;
      padding-inline: 1rem;
    }

    .heading-bg {
      background-color: var(--oe-blue-dark-500);
      width: 100%;
      position: absolute;
    }
  }

  @container aboutpage-content (width > 900px) {
    .banner-img {
      height: 70vh;
    }

    .heading {
      text-align: right;
      padding-left: var(--sp-lg);
    }

    .port-text {
      margin-top: var(--sp-4xl);
      order: 2;
    }
  }

  @container aboutpage-content (width >= 12.96l) {
    .banner-img img {
      object-position: 50% 35%;
    }
  }
}
