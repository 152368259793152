.img-wrapper {
  display: flex;
  justify-content: center;

  /* image in portable text */
  &.port-text-img {
    z-index: 0;
  }

  .aspect-thumbnail {
    aspect-ratio: 5/4;
  }

  .aspect-portrait {
    aspect-ratio: 3/4;
  }

  .cover {
    object-fit: cover;
  }

  .contain {
    object-fit: contain;
  }

  figure {
    position: relative;

    figcaption {
      position: relative;
      text-align: center;
      font-style: italic;
      line-height: normal;
      margin-block-start: var(--sp-xxs);

      &.inset-left,
      &.inset-right {
        position: absolute;
        bottom: 0;
        background-color: #ffffffb5;
        padding-block: 0.05rem;
        padding-inline-start: 0.25rem;
        padding-inline-end: 0.5rem;
        z-index: 10;
        color: var(--oe-black);
        font-size: 0.65rem;
        letter-spacing: 0.4px;
      }

      &.inset-left {
        left: 0;
      }

      &.inset-right {
        right: 0;
      }

      &.below {
        position: relative;
        font-size: var(--fs-xs);
      }
    }
  }

  img {
    transition: all;
    transition-duration: 200ms;
    transition-delay: 100ms;
    object-fit: cover;

    &.hover {
      &:hover {
        transform: scale(0.99);
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  &.port-text-img {
    @container content-well (width > 600px) {
      width: 100%;

      figure {
        &.w-20 {
          width: 20%;
        }

        &.w-25 {
          width: 25%;
        }

        &.w-33 {
          width: 33%;
        }

        &.w-50 {
          width: 50%;
        }

        &.w-66 {
          width: 66%;
        }

        &.w-75 {
          width: 75%;
        }
      }

      .float-left {
        float: left;
        margin-inline-end: var(--sp-md);
        margin-block: 0;
      }

      .float-right {
        float: right;
        margin-inline-start: var(--sp-md);
        margin-block: 0;
      }
    }
  }
}

.img-grid {
  list-style-type: none;
  margin-block: 2.5rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--sp-md);

  img {
    aspect-ratio: 10/7;
  }

  @container content-well (width >= 900px) {
    margin-block: 4rem;
    margin-inline: 1.5rem;
    gap: var(--sp-md);
  }

  @container content-well (width >= 1200px) {
    margin-inline: 2.5rem;
  }
}

.lightbox-img {
  width: 3rem;
  height: 80vh;
}
