/* CSS imports must be at the top of the file */

@import './normalize.css';
@import './variables.css';
@import './colors.css';
@import './utility/utility.css';
@import './utility/typography.css';
@import './utility/spacing.css';
@import './not-found.css';
@import './components/nav.css';
@import './components/porttext.css';
@import './components/button.css';
@import './components/heading.css';
@import './components/growingnearby.css';
@import './components/teaser.css';
@import './components/image.css';
@import './components/imagecard.css';
@import './components/imageslider.css';
@import './components/natureserve.css';
@import './components/tableofcontents.css';
@import './components/footer.css';
@import './pages/home.css';
@import './pages/plant-pages.css';
@import './pages/plant-list.css';
@import './pages/seasons-pages.css';
@import './pages/about.css';
@import './sanity/studio.css';

html {
  font-size: 16px;
  line-height: 1.15;
  scroll-behavior: smooth;
  font-family: var(--font-raleway), system-ui, sans-serif;
  font-weight: 400;

  body {
    min-height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:has(#mainNav.nav-active) {
      /* prevent scroll when the menu is open */
      overflow: hidden;
    }
  }

  * {
    color: var(--oe-text-body);
    border-style: none;
  }
}

main {
  height: 100%;
  line-height: var(--sp-lg);
}

a[href] {
  color: var(--oe-link-blue);
  text-decoration: none;
  cursor: pointer;
}

figure {
  margin: 0;
}

.banner-img:not(.mobile) {
  display: none;
}

*.hide-scroll::-webkit-scrollbar {
  display: none;
}

.content-well > *:not(.w-full) {
  container: content-well / inline-size;
  width: clamp(8rem, 90cqi, 60rem);
  margin-block: min(5vh, 2rem);
  margin-inline: auto;

  @container (min-width: 600px) {
    width: clamp(35rem, 85cqi, 55rem);
    margin-block: min(10vh, 3rem);
  }
}

.content-section {
  position: relative;

  .heading-wrapper {
    gap: var(--sp-md);

    @container content-well (min-width: 600px) {
      gap: var(--sp-xl);
      flex-direction: row;
    }
  }
}

@media screen and (min-width: 900px) {
  .banner-img:not(.mobile) {
    display: block;
  }

  .banner-img.mobile {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

/* <SanityImage> - https://github.com/coreyward/sanity-image#styling-your-images */
img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 0.375rem;
}

/* Override default Lightbox.js styles */
#lightboxContainer {
  .lightbox-arrow {
    color: white;
    font-size: 1.5rem;
  }
  p {
    color: #ffffff !important;
  }
}

.common-name {
  padding-block: var(--sp-xs);
}
.botanical-name {
  padding-block: var(--sp-xxs);
  font-weight: var(--fw-500);
}

.plant-name-wrapper {
  container: plant-name-wrapper / inline-size;

  .common-name {
    font-weight: 600;
  }

  hr {
    margin-block: 0;
  }
}
