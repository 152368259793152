body:has(.nav-dark) {
  .main-nav .nav-btn-bar {
    background-color: var(--nav-bg-dark);
  }
}

.main-nav {
  position: fixed;
  font-size: var(--fs-xl);
  z-index: 50;
  background-color: #181517;
  font-family: var(--font-primary);

  &.nav-active {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;

    .nav-btn-bar {
      background-color: var(--oe-white) !important;
    }

    .nav-grid-container {
      display: grid;
    }

    .nav-list-item:nth-child(1) {
      animation-delay: 0s;
    }

    .nav-list-item:nth-child(2) {
      animation-delay: 0.1s;
    }

    .nav-list-item:nth-child(3) {
      animation-delay: 0.2s;
    }

    .nav-list-item:nth-child(4) {
      animation-delay: 0.3s;
    }

    .nav-list-item:nth-child(5) {
      animation-delay: 0.4s;
    }

    .nav-list-item:nth-child(6) {
      animation-delay: 0.5s;
    }

    .nav-list-item:nth-child(7) {
      animation-delay: 0.6s;
    }

    .nav-list-item:nth-child(8) {
      animation-delay: 0.7s;
    }
  }

  .nav-grid-container {
    display: none;
    grid-template: 1fr / minmax(23rem, 1fr) 4fr;
    grid-template-areas: 'sidebar image';

    .nav-sidebar {
      grid-area: sidebar;
      container: sidebar / inline-size;
      width: 100%;
      background: transparent;

      .overlay {
        display: none;
        background-color: #18170c;
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -10;
  }

  .nav-list {
    margin-block-start: var(--sp-4xl);
    margin-inline-start: var(--sp-ml);
  }

  .nav-list-item {
    margin-bottom: var(--sp-md);
    animation: fadeIn 1s ease-in forwards;
    opacity: 0;
  }

  .nav-list-item-link {
    color: var(--oe-white);
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: var(--oe-red-200);
    }
  }

  .nav-icon {
    position: absolute;
    top: 1.5rem;
    left: 0.75rem;
    z-index: 30;
    border: none;
  }

  .nav-btn-bar {
    width: 2rem;
    height: 4px;
    background-color: var(--nav-bg-color);
    margin: 0.25rem 0;
  }

  .nav-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -20;
    grid-area: image;
    background-color: #18170c;

    img {
      position: absolute;
      object-fit: cover;
      object-position: 30% 100%;
    }

    .lg-img,
    .mobile-img {
      opacity: 0;
      animation: fadeIn 1s ease-in forwards;
      animation-delay: 0.3s;
    }

    .lg-img {
      display: none;
    }

    .mobile-img {
      display: block;
    }
  }

  @media screen and (min-width: 600px) {
    .nav-list {
      margin-inline-start: var(--sp-3xl);
    }
  }

  @media screen and (min-width: 900px) {
    .nav-list {
      margin-block-start: var(--sp-6xl);
      margin-inline-start: var(--sp-2xl);
    }
  }

  @media screen and (min-width: 1200px) {
    .nav-img {
      position: relative;
      grid-area: image;
      .lg-img {
        display: block;

        img {
          object-position: 100% 60%;
        }
      }
      .mobile-img {
        display: none;
      }
    }
    .nav-grid-container {
      grid-area: sidebar;

      .overlay {
        display: block;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .nav-img {
      .lg-img {
        img {
          object-position: 100% 35%;
        }
      }
    }
  }
}
