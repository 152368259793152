/* ImageSlider compmonent ------------------------- */
.image-slider {
  position: relative;
  width: 100%;
  max-height: fit-content;

  .slider {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    gap: var(--sp-sm);

    li {
      flex: none;
      scroll-snap-align: center;

      figure {
        position: relative;
        width: 9rem;
        margin-bottom: var(--sp-lg);
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 0.375rem;
        aspect-ratio: 3/4;
      }
    }
  }

  @container (width > 800px) {
    .slider {
      li {
        figure {
          width: 15rem;
        }
      }
    }
  }

  @container (width > 1200px) {
    padding-inline: var(--sp-3xl);
  }
}
