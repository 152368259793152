:root {
  --oe-white: hsl(0, 0%, 100%);
  --oe-black: hsl(0, 0%, 0%);
  --oe-text-body: hsl(240, 3%, 15%);
  --oe-link-blue: hsl(217, 91%, 60%);
  --oe-grey-100: hsl(0, 0%, 96%);
  --oe-grey-200: hsl(0, 0%, 88%);
  --oe-grey-300: hsl(0, 0%, 76%);
  --oe-grey-400: hsl(0, 0%, 58%);
  --oe-grey-500: hsl(0, 0%, 50%);
  --oe-grey-600: hsl(0, 0%, 42%);
  --oe-grey-700: hsl(0, 0%, 33%);
  --oe-grey-800: hsl(0, 0%, 25%);
  --oe-grey-900: hsl(0, 0%, 15%);
  --oe-yellow-100: hsl(60, 100%, 96%);
  --oe-yellow-200: hsl(60, 100%, 88%);
  --oe-yellow-300: hsl(60, 100%, 76%);
  --oe-yellow-400: hsl(60, 100%, 58%);
  --oe-yellow-500: hsl(60, 100%, 50%);
  --oe-yellow-600: hsl(60, 100%, 42%);
  --oe-yellow-700: hsl(60, 100%, 33%);
  --oe-yellow-800: hsl(60, 100%, 25%);
  --oe-yellow-900: hsl(60, 100%, 15%);
  --oe-orange-100: hsl(30, 100%, 96%);
  --oe-orange-200: hsl(30, 100%, 88%);
  --oe-orange-300: hsl(30, 100%, 76%);
  --oe-orange-400: hsl(30, 100%, 58%);
  --oe-orange-500: hsl(30, 100%, 50%);
  --oe-orange-600: hsl(30, 100%, 42%);
  --oe-orange-700: hsl(30, 100%, 33%);
  --oe-orange-800: hsl(30, 100%, 25%);
  --oe-orange-900: hsl(30, 100%, 15%);
  --oe-green-100: hsl(84, 50%, 93%);
  --oe-green-200: hsl(82, 36%, 82%);
  --oe-green-300: hsl(80, 30%, 76%);
  --oe-green-400: hsl(79, 39%, 63%);
  --oe-green-500: hsl(78, 45%, 50%);
  --oe-green-600: hsl(78, 39%, 46%);
  --oe-green-700: hsl(79, 33%, 33%);
  --oe-green-800: hsl(79, 29%, 27%);
  --oe-green-900: hsl(80, 30%, 20%);
  --oe-green-yellow-100: hsl(60, 67%, 96%);
  --oe-green-yellow-200: hsl(60, 67%, 88%);
  --oe-green-yellow-300: hsl(60, 67%, 76%);
  --oe-green-yellow-400: hsl(60, 50%, 70%);
  --oe-green-yellow-500: hsl(60, 67%, 58%);
  --oe-green-yellow-600: hsl(60, 50%, 45%);
  --oe-green-yellow-700: hsl(60, 50%, 35%);
  --oe-green-yellow-800: hsl(60, 50%, 25%);
  --oe-green-yellow-900: hsl(60, 50%, 15%);
  --oe-blue-green-light-100: hsl(168, 33%, 92%);
  --oe-blue-green-light-200: hsl(168, 33%, 88%);
  --oe-blue-green-light-300: hsl(168, 25%, 84%);
  --oe-blue-green-light-400: hsl(168, 25%, 70%);
  --oe-blue-green-light-500: hsl(168, 25%, 66%);
  --oe-blue-green-light-600: hsl(168, 25%, 58%);
  --oe-blue-green-light-700: hsl(168, 25%, 50%);
  --oe-blue-green-light-800: hsl(168, 25%, 38%);
  --oe-blue-green-light-900: hsl(168, 25%, 26%);
  --oe-blue-green-dark-100: hsl(192, 33%, 88%);
  --oe-blue-green-dark-200: hsl(192, 33%, 76%);
  --oe-blue-green-dark-300: hsl(192, 33%, 70%);
  --oe-blue-green-dark-400: hsl(192, 33%, 58%);
  --oe-blue-green-dark-500: hsl(192, 33%, 50%);
  --oe-blue-green-dark-600: hsl(192, 33%, 42%);
  --oe-blue-green-dark-700: hsl(192, 33%, 38%);
  --oe-blue-green-dark-800: hsl(192, 33%, 30%);
  --oe-blue-green-dark-900: hsl(192, 33%, 22%);
  --oe-blue-dark-100: hsl(216, 50%, 92%);
  --oe-blue-dark-200: hsl(216, 50%, 82%);
  --oe-blue-dark-300: hsl(216, 50%, 70%);
  --oe-blue-dark-400: hsl(216, 50%, 58%);
  --oe-blue-dark-500: hsl(216, 50%, 39%);
  --oe-blue-dark-600: hsl(216, 50%, 30%);
  --oe-blue-dark-700: hsl(216, 50%, 26%);
  --oe-blue-dark-800: hsl(216, 50%, 18%);
  --oe-blue-dark-900: hsl(216, 50%, 10%);
  --oe-brown-100: hsl(30, 100%, 96%);
  --oe-brown-200: hsl(30, 100%, 88%);
  --oe-brown-300: hsl(30, 100%, 76%);
  --oe-brown-400: hsl(30, 100%, 58%);
  --oe-brown-500: hsl(30, 100%, 50%);
  --oe-brown-600: hsl(30, 100%, 42%);
  --oe-brown-700: hsl(30, 100%, 33%);
  --oe-brown-800: hsl(30, 100%, 25%);
  --oe-brown-900: hsl(30, 100%, 15%);
  --oe-purple-100: hsl(260deg, 60%, 85%);
  --oe-purple-200: hsl(260deg, 55%, 75%);
  --oe-purple-300: hsl(260deg, 50%, 65%);
  --oe-purple-400: hsl(260deg, 47.95%, 57.06%);
  --oe-purple-500: hsl(260deg, 47.95%, 47%);
  --oe-purple-600: hsl(260deg, 50%, 37%);
  --oe-purple-700: hsl(260deg, 55%, 27%);
  --oe-purple-800: hsl(260deg, 60%, 17%);
  --oe-purple-900: hsl(260deg, 65%, 7%);
  --oe-red-100: hsl(0, 100%, 96%);
  --oe-red-200: hsl(15deg 89.54% 81.98%);
  --oe-red-300: hsl(15, 100%, 66%);
  --oe-red-400: hsl(15, 100%, 46%);
  --oe-red-500: hsl(0, 100%, 38%);
  --oe-red-600: hsl(0, 100%, 33%);
  --oe-red-700: hsl(0, 100%, 25%);
  --oe-red-800: hsl(0, 100%, 20%);
  --oe-red-900: hsl(0, 100%, 5%);
  --oe-red-bright-100: hsl(348.29deg 94.59% 96%);
  --oe-red-bright-200: hsl(348.29deg 94.59% 88%);
  --oe-red-bright-300: hsl(348.29deg 94.59% 76%);
  --oe-red-bright-400: hsl(348.29deg 94.59% 58%);
  --oe-red-bright-500: hsl(348.29deg 94.59% 43.53%);
  --oe-red-bright-600: hsl(348.29deg 94.59% 38.53%);
  --oe-red-bright-700: hsl(348.29deg 94.59% 33.53%);
  --oe-red-bright-800: hsl(348.29deg 94.59% 28.53%);
  --oe-red-bright-900: hsl(348.29deg 94.59% 23.53%);
  --oe-pink-100: hsl(336, 100%, 96%);
  --oe-pink-200: hsl(336, 100%, 88%);
  --oe-pink-300: hsl(336, 100%, 76%);
  --oe-pink-400: hsl(336, 100%, 58%);
  --oe-pink-500: hsl(336, 100%, 50%);
  --oe-pink-600: hsl(336, 100%, 42%);
  --oe-pink-700: hsl(336, 100%, 33%);
  --oe-pink-800: hsl(336, 100%, 25%);
  --oe-pink-900: hsl(336, 100%, 15%);
  --oe-presumed-extirpated: hsl(0, 0%, 40%);
  --oe-possibly-extirpated: hsl(60, 10%, 60%);
  --oe-critically-imperiled: hsl(0, 100%, 70%);
  --oe-imperiled: hsl(36, 100%, 76%);
  --oe-vulnerable: hsl(60, 100%, 76%);
  --oe-apparently-secure: hsl(204, 100%, 70%);
  --oe-secure: hsl(216, 50%, 42%);
  --spring-accent: var(--oe-blue-green-light-900);
  --summer-accent: var(--oe-green-yellow-600);
  --fall-accent: hsl(19, 52%, 25%);
  --winter-accent: var(--oe-grey-800);
  --spring-bg-color: hsl(98, 29%, 80%);
  --summer-bg-color: hsl(49.35deg 98.52% 82.81%);
  --fall-bg-color: var(--oe-orange-200);
  --winter-bg-color: hsl(210, 4%, 90%);
  --winter-bg-color-2: hsl(220, 2%, 73%);
  --winter-bg-color-3: hsl(222, 15%, 29%);
  --nav-bg-color: var(--oe-white);
  --nav-bg-dark: var(--oe-red-800);

  /* Gradients ----------------------------- */
  --spring-gradient: linear-gradient(60deg, hsl(350, 72%, 52%) 5%, hsl(134, 15%, 32%) 100%);
  --summer-gradient: linear-gradient(220deg, var(--oe-purple-200) 60%, var(--oe-purple-300) 100%);
  --fall-gradient: linear-gradient(175deg, var(--oe-brown-700) 60%, var(--oe-yellow-800) 100%);
  --winter-gradient: linear-gradient(145deg, #3b332a 0%, hsl(27, 12%, 57%) 100%);
  --winter-gradient-2: linear-gradient(90deg, hsl(215, 44%, 58%) 0%, hsl(216, 42%, 87%) 100%);
  --winter-gradient-3: linear-gradient(145deg, #39393e 0%, hsl(25, 13%, 18%) 100%);

  --btn-gradient-1: linear-gradient(to right, hsl(337, 67%, 54%), var(--oe-pink-600));
  --btn-gradient-2: linear-gradient(
    to right,
    var(--oe-blue-green-dark-500),
    var(--oe-blue-green-dark-600),
    var(--oe-blue-green-dark-700)
  );
  --btn-gradient-2-hover: linear-gradient(
    to right,
    var(--oe-blue-green-dark-400) 0%,
    var(--oe-blue-green-dark-500) 100%
  );
  --btn-gradient-3: linear-gradient(
    90deg,
    var(--oe-blue-dark-200) 0%,
    var(--oe-blue-dark-100) 100%
  );
  --btn-gradient-4: linear-gradient(to right, var(--oe-blue-dark-700), var(--oe-blue-dark-800));
  --btn-gradient-5: linear-gradient(90deg, var(--oe-red-800) 0%, var(--oe-red-700) 100%);
  --btn-gradient-6: linear-gradient(to right, #2b2728 0%, #262123 100%);
}
