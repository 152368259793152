.season-page {
  --season-accent-color: var(--oe-green-800);
  --season-bg-color: #fff;
  --season-gradient: var(--spring-gradient);
  container: season-page-content / inline-size;
  position: relative;

  &.spring {
    --season-bg-color: var(--spring-bg-color);
    --season-accent-color: var(--spring-accent);
    --season-gradient: var(--spring-gradient);
    background-color: var(--season-bg-color);

    .banner-img img {
      object-position: top;
    }
  }

  &.summer {
    --season-bg-color: var(--summer-bg-color);
    --season-accent-color: var(--summer-accent);
    --season-gradient: var(--summer-gradient);
    background-color: var(--season-bg-color);

    .banner-img img {
      object-position: center;
    }
  }

  &.fall {
    --season-bg-color: var(--fall-bg-color);
    --season-accent-color: var(--fall-accent);
    --season-gradient: var(--fall-gradient);
    background-color: var(--season-bg-color);

    .banner-img img {
      object-position: 100% 15%;
    }
  }

  &.winter {
    --season-bg-color: var(--winter-bg-color);
    --season-accent-color: var(--winter-accent);
    --season-gradient: var(--winter-gradient);
    background-color: var(--season-bg-color);

    .banner-img img {
      object-position: top;
    }
  }

  .season-header {
    grid-area: header;
    container: season-header / inline-size;

    .heading-display {
      bottom: -4rem;
      text-align: left;
      width: fit-content;
    }

    .heading-title {
      background: var(--season-gradient);
      background-clip: text;
      color: transparent;
      font-size: var(--fs-5xl);
      font-weight: var(--fw-700);
      line-height: normal;
      width: fit-content;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .banner-img {
      background-color: var(--season-bg-color);

      @container season-header (width > 900px) {
        height: 85vh;
      }

      .header-border-svg {
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 80px;
        fill: var(--season-bg-color);
      }
    }
  }

  .season-body {
    grid-area: content;
    container: season-body / inline-size;
    margin-block: var(--sp-xl);
    margin-inline: auto;
    width: 100%;

    > .port-text > *:not(.full-width) {
      max-width: 100%;
      margin-inline: auto;
    }
  }

  @container season-page-content (width > 600px) {
    .season-header .heading-display {
      bottom: -6rem;
    }

    .season-header .heading-title {
      margin-inline-end: var(--sp-xl);
      font-size: var(--fs-7xl);
    }

    .season-body {
      padding-inline: 0;
      margin-block: var(--sp-3xl);
    }
  }

  @container season-page-content (width > 900px) {
    .season-body {
      padding-inline: 0;
      margin-block: var(--sp-4xl);
    }

    .heading-display .heading-title {
      padding-inline-start: var(--sp-2xl);
      font-size: var(--fs-8xl);
      font-weight: var(--fw-900);
    }
  }

  @container season-page-content (width > 1200px) {
    .season-body {
      > .port-text > .img-grid {
        max-width: 60rem;
      }
    }

    .season-header .heading-display {
      bottom: -7rem;
      margin-inline-start: 7%;

      .text-display {
        font-size: var(--fs-9xl);
      }
    }
  }

  /* Season Page Teaser Sections */
  .teaser {
    .feature-layout-grid {
      padding: 0;
    }
  }
}
