.port-text {
  max-width: 64rem;
  /* margin-block-end: var(--sp-lg); */

  & > * {
    color: inherit;
    margin-block-end: var(--sp-lg);
  }

  a[href] {
    text-decoration: underline;
  }

  .img-grid {
    margin-block-start: 0;
  }

  .list-item {
    display: list-item;

    &.list-decimal {
      list-style-type: decimal;
      padding-inline-start: var(--sp-sm);
    }

    &.list-inside {
      list-style-position: inside;
    }

    &.disclosure-closed {
      list-style-type: disclosure-closed;
    }
  }
}
