.natureserve-message {
  position: relative;
  display: inline-flex;
  margin-bottom: var(--sp-md);
  max-width: 64rem;
  margin-inline: var(--sp-xl);
  border-radius: 0.375rem;

  .natureserve-message-text {
    display: flex;
    gap: var(--sp-xs);
    z-index: 0;
    font-size: var(--fs-sm);
    background-color: transparent;
    padding: var(--sp-md);
  }

  .natureserve-icon-info {
    display: inline-block;
    padding-right: var(--sp-xs);
  }

  .natureserve-icon-svg {
    display: inline;
    margin-bottom: var(--sp-xxs);
  }

  .natureserve-link {
    display: inline;
    text-decoration: underline;
    text-underline-offset: var(--sp-xxs);
    color: inherit;
  }

  .natureserve-message-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -10;
    border-radius: 0.375rem;
    top: 0;
    left: 0;
  }
}

.natureserve-badge {
  padding: var(--sp-xxs) var(--sp-sm);
  text-align: center;
  border-radius: 0.75rem;
  white-space: nowrap;
  width: fit-content;
  align-self: flex-start;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover,
  &:active {
    opacity: 0.9;
    transform: scale(0.95);
  }

  .natureserve-badge-text {
    display: inline-flex;
    gap: var(--sp-xs);
    font-size: var(--fs-xs);
    font-weight: var(--fw-400);
    align-items: center;
    text-transform: capitalize;
  }
}
