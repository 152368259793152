.page-not-found {
  container: page-not-found / inline-size;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-color: #0e0202;
  padding-inline: var(--sp-lg);

  * {
    color: inherit;
  }

  .heading-wrapper,
  .port-text {
    text-align: center;
    max-width: 80vw;
    line-height: 1.25;
    font-size: var(--sp-md);
    font-weight: 700;
    opacity: 0;
    animation: textFadeIn 1s ease-out forwards;
    animation-delay: 1.25s; /* Starts after circle animation completes */
  }

  h1 {
    position: relative;
    line-height: 1.25;
    font-size: var(--sp-md);

    z-index: 0;
    color: var(--oe-white);

    .first-word {
      font-size: var(--sp-lg);
      white-space: nowrap;
    }

    .heading-rest {
      display: inline;
    }
  }

  .circle-img,
  .message {
    margin-block-start: var(--sp-lg);
  }

  .message {
    order: 3;
    opacity: 0;
    animation: textFadeIn 1s ease-out forwards;
    animation-delay: 1.25s; /* Starts after circle animation completes */
  }

  .circle-img {
    width: 80vw;
    display: flex;
    justify-content: center;
    max-width: 30rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    transform: scale(0);
    transform-origin: center;
    animation: circleImageGrow 1.25s ease-out forwards;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 900px) {
  .page-not-found {
    display: grid;
    grid: 1fr 1fr/ 5fr 4fr;
    gap: 0 3rem;
    grid-template-areas:
      'img heading'
      'img message';

    .heading-wrapper {
      grid-area: heading;
      justify-self: self-start;
      align-self: self-end;
      text-align: left;

      h1 {
        font-size: var(--sp-lg);

        .first-word {
          font-size: var(--sp-2xl);
        }
      }
    }

    .message {
      grid-area: message;
      justify-self: self-start;
      align-self: self-start;
      margin-block-start: var(--sp-lg);

      .port-text {
        text-align: left;
        font-size: var(--sp-lg);
      }
    }

    .circle-img {
      display: block;
      width: 50vw;
      max-width: 40rem;
      justify-self: self-end;
      grid-area: img;
    }
  }
}

/* @container page-not-found (width > 900px) {
} */

@keyframes circleImageGrow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes textFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
